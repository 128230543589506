<template>
  <div class="container mt-10" id="screenshotsPage">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />


    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => {
            filter.StartDate = dates.StartDate;
            filter.EndDate = dates.EndDate
          }" />
        </div>
          
        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id" :key="filter.ProjectId" />
        </div>
        <div class="filter-item ml-2">
          <el-switch v-model="filter.ShowOnlyIdle" active-text="Only idle"></el-switch>
        </div>
      </div>

      <el-dropdown v-if="selectedIds.some(x => true) && $isInRole('Owner,Coowner')" class="selected-actions"> 
        <span class="el-dropdown-link">
          <i class="fas fa-spinner fa-spin" v-if="loading == 'deleteAll' || loading == 'downloadAll'"></i> {{ selectedIds.length }} selected <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item @click.native="downloadAll" :disabled="loading == 'downloadAll'"><i :class="loading == 'deleteAll' ? 'fas fa-spinner fa-spin' : 'fas fa-cloud-download-alt'"></i> Download all</el-dropdown-item>

          <el-dropdown-item @click.native="deleteAll" :disabled="loading == 'deleteAll'" class="text-red" divided><i :class="loading == 'deleteAll' ? 'fas fa-spinner fa-spin' : 'fas fa-trash'"></i> Remove all</el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>

      <el-tooltip class="item" v-if="
        selectedIds.some(x => true) && 
        screenshots.Items.filter(x => selectedIds.some(y => y == x.ID)).length < screenshots.Items.length"
        effect="dark" content="Select this page" placement="top">
        <el-button type="primary" round icon="fas fa-check-double" @click="selectAll"></el-button>
      </el-tooltip>

      <el-tooltip class="item" v-if="
        selectedIds.some(x => true) && 
        screenshots.Items.filter(x => selectedIds.some(y => y == x.ID)).length >= screenshots.Items.length"
        effect="dark" content="Unselect this page" placement="top">
        <el-button type="secondary" round icon="fas fa-check-double" @click="deselectAll"></el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData()}" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!screenshots">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" id="screenshotsTable" v-if="screenshots">

      <CoolLightBox :items="lightboxItems" :index="screenshotLightboxIndex" @close="screenshotLightboxIndex = null">
      </CoolLightBox>

      
       <NoData v-if="screenshots.TotalCount == 0" />

      <div class="screensohts-container" v-if="screenshots.TotalCount > 0" v-loading="loading == 'getData' || loading == 'deleteAll' || loading == 'downloadAll'">

        <div v-for="(item,index) in screenshots.Items" :key="item.ID + selectedIds.length + selectionRender" >
          <div class="scr-item" :class="ssStatus(item)">
            <div class="scr-image">
              <img :src="item.Screenshot" alt="" @error="retryImageLoad($event, item.Screenshot)">
              <div class="ss-selection" v-if="$isInRole('Owner,Coowner')">
                <el-checkbox :checked="selectedIds.some(x => x == item.ID)" @change="changeSelected(item.ID)" ></el-checkbox>
              </div>
            </div>
            <div class="scr-hover">
              <div class="scr-app">
                <span><b>{{ item.OrganizationApp.ActivityApp.Name }}</b></span>
                <span class="ai-category" v-if="item.OrganizationApp.ActivityApp.AICategory">{{ item.OrganizationApp.ActivityApp.AICategory }}</span>
                <span v-if="item.Url != null">{{ item.Url }}</span>
                <el-row class="mt-2">
                  <el-button type="info" icon="fas fa-search" size="mini" circle @click="screenshotLightboxIndex = index"></el-button>

                  <el-button type="danger" icon="fas fa-trash" size="mini" v-if="item.HasDeletePermission" @click="deleteScreenshot(item)" :loading="loading == 'delete-' + item.ID" circle></el-button>
                </el-row>
              </div>
            </div>
            <div class="scr-text screenshot-item-info">
              <div class="ss-info">
                <h3>{{ item.User.Title }}</h3>
                <span><i class="fas fa-calendar"></i> {{ moment(item.CreateDate).format('DD MMMM') }} <i class="fas fa-clock"></i> {{ moment(item.CreateDate).format('HH:mm') }}</span>
              </div>
              <div class="ss-detail" v-if="item.ActivityLevel != null && item.ScreenCount != null">
                <el-tooltip :content="item.ScreenCount > 1 ? 'There are ' + item.ScreenCount + ' displays connected to this computer' : 'There is 1 display connected to this computer' " placement="top">
                  <i class="fas fa-desktop text-gray"></i>
                </el-tooltip>
                <el-tooltip content="Employee's activity level. It is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds." placement="top">
                  <span class="badge" :class="$getActivityLevelBadge($percentage(item.ActivityLevel, 60))">%{{ $percentage(item.ActivityLevel, 60) }}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Pagination 
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="screenshots.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />

    </div>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';

import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

import Pagination from '@/components/Pagination';
import moment from "moment/min/moment-with-locales";

import CoolLightBox from 'vue-cool-lightbox';
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
    name: 'ScreenshotsIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
      Pagination,
      ProjectSelectbox,
      CoolLightBox
    },
    data(){
        return {
          retryCount: {},
          maxRetries: 5,
          selectedIds:[],
          selectionRender:false,
          screenshotLightboxIndex:null,
          moment,
          loading:null,
          filter: {
            ShowOnlyIdle:false,
            TeamId:null,
            EmployeeId:null,
            ProjectId:null,
            DateFilter:1,
            StartDate:null,
            EndDate:null,
            Page: 1,
            PageSize:24,
            IncludeOrganizationApps:true,
            IncludeUsers:true,
          },
        }
    },
    mounted() {
      this.$setMeta(this.meta);

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      this.getData();
    },
    methods: {
      getImageUrl(imageUrl) {
      return imageUrl; // Gerekiyorsa dinamik olarak işlem yapabilirsiniz
    },

    // Hata durumunda yeniden yükleme
    retryImageLoad(event, originalUrl) {
      const imgElement = event.target;

      // Yeniden deneme sayısını kontrol et
      if (!this.retryCount[originalUrl]) {
        this.retryCount[originalUrl] = 0;
      }

      if (this.retryCount[originalUrl] < this.maxRetries) {
        this.retryCount[originalUrl] += 1;

        // Bir süre bekleyerek tekrar dene
        setTimeout(() => {
          console.log(
            `Retrying image: ${originalUrl}, attempt ${this.retryCount[originalUrl]}`
          );
          imgElement.src = `${originalUrl}&retry=${this.retryCount[originalUrl]}`; // Yeni bir query parametre ekleyerek yeniden deneme
        }, 1000); // 1 saniye bekle
      } else {
        console.error(`Failed to load image after ${this.maxRetries} attempts:`, originalUrl);
        imgElement.src = "/img/worktivity-horizontal-logo-white.4281cef6.svg"; // Yüklenemezse bir yedek görsel göster
      }
    },
      ssStatus(item) {
        return item.Status == 3 ? "idle" : "";
      },
      changeSelected(id) {
        if(this.selectedIds.some(x => x == id))
        {
          this.selectedIds = this.selectedIds.filter(x => x != id);
        }
        else
        {
          if(this.selectedIds.length < 200)
          {
            this.selectedIds.push(id);
          }
          else
          {
            this.$message.info("You can select maximum 200 items");
          }
        }
        this.selectionRender = !this.selectionRender;
      },
      selectAll() {
        for (let i = 0; i < this.screenshots.Items.length; i++) {
          const element = this.screenshots.Items[i];
          if(!this.selectedIds.some(x => x == element.ID))
          {
            if(this.selectedIds.length < 200)
            {
              this.selectedIds.push(element.ID);
            }
          }
        }
        this.selectionRender = !this.selectionRender;
      },
      deselectAll() {
        for (let i = 0; i < this.screenshots.Items.length; i++) {
          const element = this.screenshots.Items[i];
          if(this.selectedIds.some(x => x == element.ID))
          {
            this.selectedIds = this.selectedIds.filter(x => x != element.ID);
          }
        }
        this.selectionRender = !this.selectionRender;
      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("screenshots", this.filter)
        this.loading = null;
      },
      async deleteAll() {

        this.$confirm('You are about to delete this screenshots. This action can not be undo, are you sure about this? This make take a while. Please DO NOT close this screen.', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.loading = 'deleteAll';
          
          var res = await this.$store.dispatch("deleteScreenshots",{ Ids: this.selectedIds });
          if(res != null)
          {
            await this.getData();
            this.selectedIds = [];
          }
          this.loading = null;
        }).catch(() => {
           this.loading = null; 
        });
      },
      async downloadAll() {
        

        this.$confirm('You are about to download this screenshots. This make take a while. Please DO NOT close this screen.', 'Warning', {
          confirmButtonText: 'Yes, download',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.loading = 'downloadAll';
          
          var res = await this.$store.dispatch("downloadScreenshots",{ Ids: this.selectedIds });
          if(res != null)
          {
            await this.getData();
            this.selectedIds = [];
          }
          this.loading = null;
        }).catch(() => {
           this.loading = null; 
        });
      },
      async deleteScreenshot(item) {
        this.loading = 'delete-' + item.ID;

        this.$confirm('You are about to delete a screenshot. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("deleteScreenshot",{ Id: item.ID });
          await this.getData();
          this.loading = null;
        }).catch(() => {
         this.loading = null;
        });
      }
    },
    computed: {
      meta() {
        return {
          title: 'Screenshots',
          desc:'We found ' + (this.screenshots != null ? this.screenshots.TotalCount : '...') + ' screenshots in your account.'
        };
      },
      account() {
          return this.$store.getters.getAccount;
      },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
      screenshots() {
        return this.$store.getters.getScreenshots
      },
      employees() {
        return this.$store.getters.getEmployees
      },
      lightboxItems() {
        return this.screenshots.Items.map(x => ({
          title: x.User.Title,
          description: x.OrganizationApp.ActivityApp.Name + (x.OrganizationApp.ActivityApp.AICategory != null ? ' / '  + x.OrganizationApp.ActivityApp.AICategory : '') + '' + (x.Url != null ? ' | ' + x.Url : ''),
          src: x.Screenshot
        }));
      },
    },
    watch: {
      'filter.StartDate'() {
        if (this.filter.Page != 1) {
          this.filter.Page = 1;
        }
        else {
          this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }

        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.EmployeeId'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.ProjectId'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.ShowOnlyIdle'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("screenshotsPage");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("screenshotsPage");
      }
    }
}
</script>

<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => {
            filter.StartDate = dates.StartDate;
            filter.EndDate = dates.EndDate
          }" />
        </div>


        <div class="filter-item" v-if="account.CurrentOrganization.TrackSettings.EnableProjectManagement">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id"
            :key="filter.ProjectId" />
        </div>

        <div class="filter-item"
          v-if="account.CurrentOrganization.TrackSettings.EnableProjectManagement && filter.ProjectId != null">
          <TaskSelectbox :projectId="filter.ProjectId" @changed="(id) => filter.ProjectTaskId = id"
            :key="filter.ProjectId" />
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="filterData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <MissingOrganizationInfo />





    <template v-if="showToday && productivity">
      <div class="card bg-orange p-5 mb-5"
        v-if="account.CurrentOrganization.TrackSettings.ExpectedWorkHours > productivity.Working">
        <div class="card-alert">
          <i class="fas fa-exclamation-triangle"></i>
          <div>
            <p class="mb-1"><b>Today:</b> Expected work time: <b>{{
              $parseTime(account.CurrentOrganization.TrackSettings.ExpectedWorkHours) }}</b></p>
            <span>You need to work <b>{{ $parseTime(account.CurrentOrganization.TrackSettings.ExpectedWorkHours -
              productivity.Working) }}</b> to complete your daily expected work time.</span>
          </div>
        </div>
      </div>
      <div class="card bg-green p-5 mb-5" v-else>
        <div class="card-alert">
          <i class="fas fa-check-circle"></i>
          <div>
            <p class="mb-1"><b>Today:</b> Expected work time: <b>{{
              $parseTime(account.CurrentOrganization.TrackSettings.ExpectedWorkHours) }}</b></p>
            <span>You worked <b>{{ $parseTime(productivity.Working) }}</b> its time to rest.</span>
          </div>
        </div>
      </div>
    </template>

    <div class="card p-5" v-if="!productivity || !activityStats">
      <el-skeleton :rows="12" animated />
    </div>

    <div class="card p-5 pb-10" style="min-height: 111px;" v-if="productivity && activityStats" v-loading="loading == 'getDataActivityStats'">
      <div class="card-header hr ">
        <div class="card-title">
          <h3>Activity bar</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge no-cursor blue">{{ moment(statsFilter.StartDate).format('DD MMMM yyyy, dddd') }}</span>
        </div>


      </div>

      <div class="activity-bar" v-show="showActivityBar">
        <span v-for="(item, index) in activityStats.Items" :key="item.StartDate + item.Status + index" class="stat-view"
          :style="'width:' + (item.Duration * 100) / $sum(activityStats.Items, 'Duration') + '%'"
          :class="(' background-' + getStatusBadgeBg(item.Status))" :data-date="item.StartDate" :data-url="item.Url" :data-appname="item.AppName" :data-status="item.Status" >
          <div class="activity-stat-info shadow-md">
            <span class="status">{{ (item.Status != null ? $getEnumTitle('EmployeeActivityLogStatus', item.Status) :
              'Inactive') }}</span>
            <span class="date" v-if="item.Duration > 1">
              {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }} =>
              {{ moment(item.EndDate).format('DD MMM yyyy - HH:mm') }}
            </span>
            <span class="date" v-else>
              {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }}
            </span>
            <span class="date" v-if="item.Duration > 1">
              <b>{{ $parseTime(item.Duration) }}</b>
            </span>

            <div class="apps">
              <img v-if="item.Icon != null" :src="item.Icon" alt="">
              <template v-if="item.Url != null">
                <span>{{ item.Url }}</span>
              </template>
              <template v-else>
                <span>{{ item.AppName }}</span>
              </template>
            </div>
          </div>
          <div class="check-point" v-if="item.Status == 0 || item.Status == 4"
            :class="(item.Status == 0 ? 'clockin' : '') + (item.Status == 4 ? 'clockout' : '')">
            <i class="fas fa-bolt" v-show="item.Status == 0"></i>
            <i class="fas fa-door-open" v-show="item.Status == 4"></i>
          </div>
        </span>
      </div>
    </div>

    <div class="card p-5 mt-10" v-if="productivity && activityStats" v-loading="loading == 'getDataProductivity'">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Total calculated time:</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge no-cursor"
            :class="workTimeBadgeBg(parseInt($percentage(productivity.Working, productivity.TotalMins)))">{{
              $parseTime(productivity.TotalMins) }}</span>
        </div>
      </div>

      <div class="progress-container">
        <div class="progress-item">
          <div class="progress-overview green">
            <i class="fas fa-bolt"></i>
            <div class="progress-info">
              <h6>Working</h6>
              <span>Total {{ $parseTime(productivity.Working) }}</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12"
              :percentage="parseInt($percentage(productivity.Working, productivity.TotalMins))"
              :color="'#45C4A0'"></el-progress>
          </div>
        </div>

        <div class="progress-item">
          <el-tooltip
            content="It is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds. (Information about which keys the user pressed or where they clicked on the screen is not stored at all; only proportional calculations are performed.)"
            placement="top">
            <div class="progress-overview purple">
              <i class="fas fa-keyboard"></i>
              <div class="progress-info">
                <h6>Activity level</h6>
                <span v-if="productivity.ActivityLevel > 0">avg. <b>{{ parseInt(productivity.ActivityLevel / productivity.Working) }}</b> sec. per min. </span>
                <span v-else>avg. <b>0</b> sec. per min. </span>
              </div>
            </div>
          </el-tooltip>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12"
              :percentage="parseInt($percentage((productivity.ActivityLevel / 60), productivity.Working))"
              :color="$getAcitivtyLevelBadgeColor(parseInt($percentage((productivity.ActivityLevel / productivity.Working), 60)))"></el-progress>
          </div>
        </div>

        <div class="progress-item">
          <div class="progress-overview blue">
            <i class="fas fa-mug-hot"></i>
            <div class="progress-info">
              <h6>On break</h6>
              <span>Total {{ $parseTime(productivity.OnBreak) }}.</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12"
              :percentage="parseInt($percentage(productivity.OnBreak, productivity.TotalMins))"
              :color="'#54A8C7'"></el-progress>
          </div>
        </div>

        <div class="progress-item">
          <div class="progress-overview orange">
            <i class="fas fa-bed"></i>
            <div class="progress-info">
              <h6>Idle</h6>
              <span>Total {{ $parseTime(productivity.Idle) }}</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12"
              :percentage="parseInt($percentage(productivity.Idle, productivity.TotalMins))"
              :color="'#f78b77'"></el-progress>
          </div>
        </div>

      </div>
    </div>

    <div class="card p-5 mt-10" v-if="productivity && activityStats">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Total calculated work time:</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge no-cursor"
            :class="productivityBadgeBg(parseInt($percentage(productivity.Productive, productivity.Working)))">{{
              $parseTime(productivity.Working) }}</span>
        </div>
      </div>

      <div class="percentage-container">
        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard"
              :percentage="parseInt($percentage(productivity.Productive, productivity.Working))" :stroke-width="12"
              :color="'#45C4A0'"></el-progress>
          </div>
          <div class="percentage-overview green">
            <i class="fas fa-check-circle"></i>
            <div class="percentage-info">
              <h6>Productive</h6>
              <span>Total {{ $parseTime(productivity.Productive) }}</span>
            </div>
          </div>
        </div>

        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard"
              :percentage="parseInt($percentage(productivity.Natural, productivity.Working))" :stroke-width="12"
              :color="'#54A8C7'"></el-progress>
          </div>
          <div class="percentage-overview blue">
            <i class="fas fa-minus-circle"></i>
            <div class="percentage-info">
              <h6>Neutral</h6>
              <span>Total {{ $parseTime(productivity.Natural) }}</span>
            </div>
          </div>
        </div>

        <div class="percentage-item">
          <div class="circular-bar">
            <el-progress type="dashboard"
              :percentage="parseInt($percentage(productivity.Unproductive, productivity.Working))" :stroke-width="12"
              :color="'#E2626B'"></el-progress>
          </div>
          <div class="percentage-overview red">
            <i class="fas fa-times-circle"></i>
            <div class="percentage-info">
              <h6>Unproductive</h6>
              <span>Total {{ $parseTime(productivity.Unproductive) }}</span>
            </div>
          </div>
        </div>

      </div>

    </div>




    <div class="card p-5 mt-10 mb-10" id="activityHistoryTable" v-if="activityStats && activityLogs">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Activity history</h3>
        </div>
      </div>

      <div class="activity-table">

        <NoData v-if="activityLogs.TotalCount == 0" />
        <el-table :data="activityLogs.Items" :row-class-name="tableRowClassName" v-if="activityLogs.TotalCount > 0"
          v-loading="loading == 'getData'">
          <el-table-column label="Application" min-width="250">
            <template slot-scope="scope">
              <div class="app-title">
                <el-avatar :size="25" shape="square" :src="scope.row.OrganizationApp.ActivityApp.Icon"></el-avatar>
                <div>
                  <span v-if="scope.row.WorkNote != null && scope.row.WorkNote != ''" class="badge badge-sm  blue">{{ scope.row.WorkNote }}</span> <span>{{ scope.row.OrganizationApp.ActivityApp.Name }}
                    <a target="_blank" :href="'http://' + scope.row.OrganizationApp.ActivityApp.Url"
                      v-if="scope.row.OrganizationApp.ActivityApp.Url">{{ scope.row.OrganizationApp.ActivityApp.Url
                      }}</a> 
                  </span>
                  <el-tooltip class="ai-item" effect="dark"
                    :content="'The Worktivity AI model automatically identified this application as suitable for the ' + scope.row.OrganizationApp.ActivityApp.AICategory + ' category.'"
                    placement="bottom" v-if="scope.row.OrganizationApp.ActivityApp.AICategory != null">
                    <p><i class="fa fa-info-circle"></i> {{ scope.row.OrganizationApp.ActivityApp.AICategory }}</p>
                  </el-tooltip>

                  
                </div>

              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="Task" width="200" v-if="account.CurrentOrganization.TrackSettings.EnableProjectManagement">
            <template slot-scope="scope">
              {{ scope.row.ProjectTask.Title }}
            </template>
          </el-table-column> -->

          <el-table-column width="150" class-name="text-center">
            <template slot="header">
              Activity level
              <el-tooltip
                content="It is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds. (Information about which keys the user pressed or where they clicked on the screen is not stored at all; only proportional calculations are performed.)"
                placement="top">
                <i class="fas fa-info-circle info"></i>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <span class="badge" :class="$getActivityLevelBadge($percentage(scope.row.ActivityLevel, 60))"
                v-if="scope.row.Status == 1">%{{ $percentage(scope.row.ActivityLevel, 60) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Productivity" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :class="$getProductivityBadgeBg(scope.row.Productivity)">{{
                $getEnumTitle('ProductivityStatus', scope.row.Productivity) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Status" width="150" class-name="text-center">
            <template slot-scope="scope">

              <span class="badge" :class="getStatusBadgeBg(scope.row.Status)">{{
                $getEnumTitle('EmployeeActivityLogStatus', scope.row.Status) }}</span>


            </template>
          </el-table-column>
          <el-table-column label="Date" width="150">
            <template slot-scope="scope">
              <span>{{ moment(scope.row.CreateDate).format("DD.MM.yyyy HH:mm") }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <Pagination :Page="filter.Page" :PageSize="filter.PageSize" :Total="activityLogs.TotalCount"
        @sizeChanged="(args) => { filter.PageSize = args }" @pageChanged="(args) => { filter.Page = args }" />
    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/views/partials/Breadcrumb';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import Pagination from '@/components/Pagination';
import moment from "moment/min/moment-with-locales";
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';
import MissingOrganizationInfo from '@/components/form/MissingOrganizationInfo.vue';

export default {
  name: 'MyActivitiesIndex',
  components: {
    Breadcrumb,
    Pagination,
    DateTypeFilterSelectbox,
    DatePicker,
    ProjectSelectbox,
    TaskSelectbox,
    MissingOrganizationInfo
  },

  data() {
    return {
      moment,
      meta: {
        title: 'My Activities',
        desc: 'You can see your activities here.'
      },
      showActivityBar:false,
      loading: null,
      showToday: true,
      filter: {
        EmployeeId: null,
        DateFilter: 1,
        StartDate: null,
        EndDate: null,
        ProjectId: null,
        ProjectTaskId: null,
        IncludeOrganizationApps: true,
        Page: 1,
        PageSize: 12,
        IncludeTasks: false
      },
      statsFilter: {
        EmployeeId: null,
        DateFilter: 1,
        StartDate: null,
        EndDate: null
      }
    }
  },
  async mounted() {
    this.$setMeta(this.meta);


    this.statsFilter.StartDate = this.$getToday()[0];
    this.statsFilter.EndDate = this.$getToday()[1];
    this.statsFilter.EmployeeId = this.currentEmployee.ID;

    this.filter.EmployeeId = this.currentEmployee.ID;
    this.filter.EndDate = this.$getToday()[1];
    this.filter.StartDate = this.$getToday()[0];
  },
  methods: {
    async filterData() {

      this.filter.Page = 1;

      await this.getDataActivityStats();
      await this.getDataProductivity();
      await this.getData();
    },
    workTimeBadgeBg(percentage) {
      if (percentage < 60) {
        return "red";
      }
      else if (percentage < 80) {
        return "orange";
      }
      return "green"
    },
    productivityBadgeBg(percentage) {
      if (percentage < 60) {
        return "red";
      }
      else if (percentage < 80) {
        return "orange";
      }
      return "green"
    },
    getStatusBadgeBg(status) {
      switch (status) {
        case 0:
        case 1:
          return "green";
        case 2: //OnBreak
          return "blue";
        case 3: //Idle
          return "orange";
        case 4: //Stopped Work
          return "red";
        case 5: //Yet to start
          return "yellow";
        default:
          return "soft-gray";
      }
    },
    async getData() {
      this.loading = "getData";
      

      // await Promise.all([
      //     await this.$store.dispatch("productivity", this.filter),

      //   ]);
      await this.$store.dispatch("activityLogs", this.filter);

      this.showToday = this.$getToday()[0].toString() == this.filter.StartDate.toString()
      this.loading = null;
      
    },
    async calcSpans() {
      const spans = document.querySelectorAll(".stat-view");
      for (let i = 0; i < spans.length; i++) { 
        if(i == 0 || i + 1 == spans.length)
        {
            continue;
        }
        
        const currentClasses = spans[i].classList;
	      const width = spans[i].style.width;
	      const widthFloat = parseFloat(width)
        if (widthFloat < 0.07 && currentClasses.contains("background-soft-gray")) {
            const leftClasses = spans[i - 1].classList;
            const rightClasses = spans[i + 1].classList;
            
            
            //var changed = false;
            // Solundaki class'ı bul
            const leftClass = [...leftClasses].find(cls => cls !== "stat-view" && cls !== "background-soft-gray");
            const rightClass = [...rightClasses].find(cls => cls !== "stat-view" && cls !== "background-soft-gray");
            if (leftClass && leftClass !== "background-soft-gray" && rightClass && rightClass !== "background-soft-gray") {
                spans[i].classList.remove("background-soft-gray");
                spans[i].classList.add(leftClass); // Solundakinin class'ını ver

                // let date = spans[i - 1].getAttribute('data-date');
                // let appname = spans[i - 1].getAttribute('data-appname');
                // let url = spans[i - 1].getAttribute('data-url');
                // let status = spans[i - 1].getAttribute('data-status');
                // let activitylevel = spans[i - 1].getAttribute('data-activitylevel');

                // console.log("left",date,appname,url,status);
                // //activityLogCreate

                // await this.$store.dispatch("activityLogCreate", {
                //   CreateDateUtc: date,
                //   Url: url,
                //   Status:parseInt(status),
                //   AppName: appname,
                //   ActivityLevel:60,
                //   ScreenCount: 1,
                // });

               
            }

            // if(changed)
            // {
            //   await this.getDataActivityStats();
            //   await this.getDataProductivity();
            //   await this.getData();
            // }
        }
    }
    },
    async getDataActivityStats() {
      this.showActivityBar = false;


      this.loading = "getDataActivityStats";
      await this.$store.dispatch("activityEmployeeStatistics", this.statsFilter);
      this.loading = null;

      setTimeout(() => {
        this.$nextTick(function () {
      this.calcSpans();
        setTimeout(() => {
          this.showActivityBar = true;
        }, (500));
      });
      }, 300);

    },
    async getDataProductivity() {
      this.loading = "getDataProductivity";
      await this.$store.dispatch("productivity", this.filter)
      this.loading = null;
    },
    tableRowClassName({ row, rowIndex }) {
      switch (row.Status) {
        // case 0:
        // case 1:
        //     return "green-row";
        // case 2: //OnBreak
        //     return "blue-row";
        case 3: //Idle
          return "orange-row";
        // case 4: //Stopped Work
        //     return "red-row";
        // case 5: //Yet to start
        //     return "yellow-row";
        default:
          return "";
      }
    },
    async startDateWatch() {
      await this.getDataActivityStats();
      await this.getDataProductivity();
      await this.getData();
    }
  },
  computed: {
    activityLogs() {
      return this.$store.getters.getActivityLogs;
    },
    activityStats() {
      return this.$store.getters.getActivityStats;
    },
    productivity() {
      if (!this.$store.getters.getProductivity) {
        return null;
      }

      return this.$store.getters.getProductivity.Items[0];
    },
    account() {
      return this.$store.getters.getAccount;
    },
    currentEmployee() {
      return this.account.Employings.Items.find(x => x.OrganizationId == this.account.CurrentOrganization.ID);
    }
  },
  watch: {
    'filter.StartDate'() {
      this.statsFilter.StartDate = this.filter.StartDate;
      this.statsFilter.EndDate = this.filter.EndDate;
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.startDateWatch();

      }
    },
    'filter.ProjectId'() {
      this.filter.ProjectTaskId = null;

      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.ProjectTaskId'() {
      if (this.filter.Page != 1) {
        this.filter.Page = 1;
      }
      else {
        this.getData();
      }
    },
    'filter.Page'() {
      this.getData();
      this.$toTop("activityHistoryTable");
    },
    'filter.PageSize'() {
      this.filter.Page = 1;
      this.getData();
      this.$toTop("activityHistoryTable");
    }
  }
}
</script>

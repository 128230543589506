<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
     
    
    <template v-if="sub">
      <div class="card p-7 mb-10 bg-blue" v-if="sub.Subscription">
      <div class="card-header">
        <div class="card-title">
          <h3>Your current subscription plan is "{{sub.Subscription.Plan.Title}}"</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">

        <template v-if="sub.Subscription.IsLifetime">
          <li>You have a <b>life-time access</b> to worktivity features.</li>
          <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
          <!-- <li>If you need more employees you have <b>%50 OFF</b> for all plans. You can start a new subscription anytime.</li> -->
        </template>
        <template v-else>
          <template v-if="sub.Subscription.Plan.Id == '641adf5362535c09fc3d8422'">
            <li>Your free trial will be end at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b>.</li>
            <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
          </template>
          <template v-else>
            <template v-if="sub.Subscription.IsCancelled">
              <li>You subscription is cancelled.</li>
              <li>Your subscription will be end at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b>.</li>
            </template>
            <template v-else>
              <li>Your subscription will be renew at <b>{{ moment(sub.Subscription.EndDate).format("DD MMMM yyyy, HH:mm") }}</b>.</li>
              <li>You have <b>{{ sub.Subscription.Users }}</b> employee limit in your account.</li>
              <li v-if="sub.Subscription.PaddlePriceId != null">Click <a href="javascript:;" @click="editSubscriptionModal = true">here</a> to change your current plan.</li>
            </template>
          </template>
        </template>
        <li class="text-danger" v-if="sub.Subscription.PaddlePriceId == null">IMPORTANT NOTICE - We are switching to our subscription service to new provider, you need to re-subscribe after your subscription period is over.</li>

        <li class="text-info" v-if="sub.Subscription.PaddlePriceId != null">We are using Paddle for payment provider, you can access Paddle Customer Portal by clicking <a href="https://customer-portal.paddle.com/cpl_01jhj46hwx2epe88c4ya24jfsm" target="_blank">here.</a></li>
        <li v-if="sub.Subscription.CustomDataRetentionMonths != null">Your data older than <b>{{ sub.Subscription.CustomDataRetentionMonths }} months</b> will be automatically deleted.</li>

      </ul>
    </div>
    <div class="card p-7 mb-10 bg-blue" v-else>
      <div class="card-header">
        <div class="card-title">
          <h3>You dont have any subscription plan</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">
        <li>If you have any questions, please feel free to <a href="/contact-us">contact us</a>.</li>
        <li>You need to start a subscription to use worktivity.</li>
        <li class="text-info">If you already a customer but need to change your payment methods or download older invoices, you can access Paddle Customer Portal by clicking <a href="https://customer-portal.paddle.com/cpl_01jhj46hwx2epe88c4ya24jfsm" target="_blank">here.</a></li>

      </ul>
    </div>

    <div class="card p-5 mb-10" id="start-subscription-page" v-if="paddlePlans && (!sub.Subscription || sub.Subscription.Plan.Id == '641adf5362535c09fc3d8422' || sub.Subscription.PaddlePriceId == null)">
      <div class="card-header hr mb-5">
        <div class="card-title" style="flex-direction: column; align-items:start">
          <h3>Start new subscription</h3>
          <p>All prices are exclusive of tax.</p>
        </div>
        <div class="card-actions">
          <div class="mr-5">
            <el-switch v-model="yearly" active-text="Pay annually (2 months free)"></el-switch>
          </div>
          <el-button type="primary" size="medium" @click="openPricing" round>See plan details</el-button>
          
        </div>
      </div>
      <div class="sub-item-conteiner mb-5">
          <div class="sub-item" :class="item.id == formData.PlanId ? 'selected' : ''" v-for="item in paddlePlans.Items" :key="item.id">
            <div class="sub-item-title">
              <h5>
                {{ item.name }}
              </h5>
              <template v-for="inner in item.prices">
                <span v-if="yearly == true && inner.billing_cycle.interval == 'year'" class="plan-details">
                  <span>${{(inner.unit_price.amount/100).toFixed(2)}} per employee / annually</span>
                  <el-tooltip class="item" effect="dark" content="How many employees do you have including you?" placement="top">
                    <el-input-number v-model="inner.count"  :min="inner.min" :max="9999" style="width: 150px !important; margin-right: 15px !important;"></el-input-number>
                  </el-tooltip>
                <el-button type="success" round @click="startPaddleSubscription(item, inner)">Subscribe</el-button>
                <span v-if="inner.count >= inner.min">${{((inner.unit_price.amount * inner.count) / 100).toFixed(2)}} {{ yearly ? 'annually' : 'monthly' }}</span>
                </span>
                <span v-if="yearly == false && inner.billing_cycle.interval == 'month'" class="plan-details">
                  <span>${{(inner.unit_price.amount/100).toFixed(2)}} per employee / monthly</span>
                  <el-tooltip class="item" effect="dark" content="How many employees do you have including you?" placement="top">
                  <el-input-number v-model="inner.count"  :min="inner.min" :max="9999" style="width: 150px !important; margin-right: 15px !important;"></el-input-number>
                </el-tooltip>
                <el-button type="success" round @click="startPaddleSubscription(item, inner)">Subscribe</el-button>
                <span v-if="inner.count >= inner.min">${{((inner.unit_price.amount * inner.count) / 100).toFixed(2)}} {{ yearly ? 'annually' : 'monthly' }}</span>
                </span>
              </template>
            </div>
          </div>
        </div>
    </div>
    </template>

  <el-dialog :close-on-click-modal="false" class="medium" title="Change subscription" :visible.sync="editSubscriptionModal" center modal-append-to-body destroy-on-close>
    <EditSubscriptionModal @closeDialog="editSubscriptionModal = false" v-if="editSubscriptionModal"/>
  </el-dialog>

  </div>
</template>

<script>
import moment from "moment/min/moment-with-locales";
import CancelSubscriptionModal from './CancelSubscriptionModal.vue';
import EditSubscriptionModal from './EditSubscriptionModal.vue';
import RedeemCodeModal from './RedeemCodeModal.vue';
import UpdateInvoiceSettingsModal from '@/views/organization/UpdateInvoiceSettingsModal.vue';
import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'StartSubscriptionIndex',
    components: {
      Breadcrumb,
      CancelSubscriptionModal,
      EditSubscriptionModal,
      RedeemCodeModal,
      UpdateInvoiceSettingsModal
    },

    data(){
      return {
        moment,
        yearly:true,
        editSubscriptionModal:false,
        startNewSubscription:false,
        meta: {
          title: 'Manage subscription',
          desc:'You can manage your subscription here.'
        },
        loading:null,
        formData: {
          PlanId: null,
          UserCount: 10,
          Yearly:false,
          CardHolder: '',
          CardNumber: '',
          ExpireMonth: '',
          ExpireYear: '',
          Cvc: '',
          Aggreements: false
        },
        showCancellationModal:false,
        redeemCodeModal:false,
        htmlContent:null,
        invoiceInformationModal:false,
      }
    },
    async mounted() {
      this.$setMeta(this.meta);

      

      await Promise.all([
        this.$store.dispatch("getPaddlePlans", {}),
        this.$store.dispatch("getPlans"),
        this.$store.dispatch("getSubscription"),
        this.$store.dispatch("getEmployees")
      ]);
      
      this.formData.UserCount = this.employees.TotalCount;
      if(this.employees.TotalCount < 5)
      {
        this.formData.UserCount = 5;
      }
      
      if(this.sub == null || this.sub.Subscription == null || (this.sub.Subscription.EndDate < new Date()) || this.sub.Subscription.Plan.Id == '641adf5362535c09fc3d8422')
      {
        this.startNewSubscription = true;
      }


      if(this.$route.query.action)
      {
        if(this.$route.query.status == "success")
        {
          try {
            var begin_purchase = JSON.parse(localStorage.getItem("begin_purchase"));
            this.$gtag.event('purchase', {
              transaction_id: this.$route.query.transactionId,
              currency: "USD",
              value: begin_purchase.Price,
              tax:0,
              shipping:0,
              items: [
                {
                  item_id: begin_purchase.Plan.ID,
                  item_name: begin_purchase.Plan.Title,
                  item_brand: "Worktivity",
                  item_category: "Subscription",
                  price: begin_purchase.Price,
                  quantity: begin_purchase.UserCount
                }
              ]
            });
          } catch (error) {
            
          }

          this.$message.success("Thank you for your payment, your subscription started successfully.");
        }
        else
        {
          if(this.$route.query.message)
          {
            this.$message.info(this.$route.query.message);
          }
        }
      }
    },
    methods: {
      startPaddleSubscription(product, price) {
        var itemsList = [
          {
            priceId: price.id,
            quantity: price.count
          }
        ];
        Paddle.Checkout.open({
          settings: {
            displayMode: "overlay",
            theme: "light",
            locale: this.account.User.Timezone == 'Europe/Istanbul' ? "tr" : "en"
          },
          customer: {
            email: this.account.User.Email,
            //name: this.account.User.FirstName + " " + this.account.User.LastName
          },
          customData: {
            userId: this.account.User.ID,
            organizationId: this.account.CurrentOrganization.ID,
          },
          items: itemsList,
        });
      },
      selectPackage(item) {
        this.formData.PlanId = item.ID;

        this.$gtag.event('add_to_cart', {
            currency: "USD",
            value: parseFloat(item.Price.toFixed(2)),
        });

        
      },
      async subscriptionCancelled() {
        await Promise.all([
          this.$store.dispatch("getPlans"),
          this.$store.dispatch("getSubscription"),
          this.$store.dispatch("getEmployees")
        ]);
      },
      openPricing() {
        window.open("https://useworktivity.com/pricing", "_blank");
      },
      openCancelModal() {
        this.showCancellationModal = true;
      },
      async subscribe() {
        if(!this.formData.Aggreements)
        {
          this.$message.info("Please confirm the aggreements before you proceed.");
          return;
        }

        if(this.organization.Invoice == null || this.organization.Invoice.CommercialTitleOrFullName == null)
        {
          //this.$message.info("Please fill your invoice information.");
          this.invoiceInformationModal = true;
          return;
        }

        this.loading = 'subscribe';
        var res = await this.$store.dispatch("startSubscription",this.formData);
        if(res != null)
        {
          try {
            this.$gtag.event('begin_purchase', {
              currency: "USD",
              value: parseFloat((this.plans.Items.find(x => x.ID == this.formData.PlanId).Price * this.formData.UserCount * (this.formData.Yearly ? 10 : 1)).toFixed(2)),
            });

            localStorage.setItem("begin_purchase", JSON.stringify({
              Plan: this.plans.Items.find(x => x.ID == this.formData.PlanId),
              PlanId: this.formData.PlanId,
              UserCount: this.formData.UserCount,
              Yearly:this.formData.Yearly,
              Price: parseFloat((this.plans.Items.find(x => x.ID == this.formData.PlanId).Price * this.formData.UserCount * (this.formData.Yearly ? 10 : 1)).toFixed(2))
            }));
          } catch (error) {
            
          }

          this.htmlContent = res.HtmlContent;

          this.$nextTick(function () {
            //document.getElementById("iyzico-3ds-form").submit();
            setTimeout(() => {
              var forms = document.getElementById("htmlContent").getElementsByTagName("form");
              if(forms.length == 1)
              {
                document.getElementById("htmlContent").getElementsByTagName("form")[0].submit()
              }
              else
              {
                document.getElementById("htmlContent").getElementsByTagName("form")[forms.length - 1].submit()
              }
              
            },500);
          });
        }
        this.loading = null;
      }
    },
    computed: {
      years() {
        var result = [];
        for (let i = 2024; i <= 2100; i++) {
          result.push(i);
        }

        return result;
      },
      sub() {
          return this.$store.getters.getSubscription;
      },
      paddlePlans() {
          return this.$store.getters.getPaddlePlans;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      plans() {
          return this.$store.getters.getPlans;
      },
      employees() {
        var a = this.$store.getters.getEmployees;
        a.TotalCount = a.Items.filter(x => x.Blocked != true).length;
        if(this.sub != null && this.sub.Subscription != null && this.sub.Subscription.LifetimeFreeUsers)
        {
          a.TotalCount -= this.sub.Subscription.LifetimeFreeUsers;
        }
        return a;
      },
    },
}
</script>

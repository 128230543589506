
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-blue p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p>You are about to change your subscription.</p>
            <p>When you increase your number of licenses or upgrade to a higher subscription plan, the fees until your subscription renewal period will be automatically calculated and charged to your registered card today.</p>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="cancelSubscription">
        <el-form-item label="Subscription plan" required>
          
          <el-select v-model="formData.PriceId" placeholder="Select a plan...">
            <el-option
              v-for="item in plans"
              :key="item.price.id"
              :label="item.price.name"
              :value="item.price.id">
            </el-option>
          </el-select>
          <ValidationErrorItem validationKey="PriceId" />
        </el-form-item>

        <el-form-item label="Licence count" required>
          <el-input-number v-model="formData.Count"  :min="5" :max="9999" style="width:100%;"></el-input-number>
          <ValidationErrorItem validationKey="CancellationReason" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="success" icon="fas fa-check-circle" @click="editSubscription" :loading="loading == 'edit'">Change subscription</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EditSubscriptionModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            PriceId:null,
            Count:null,
          }
        }   
    },
    mounted() {
      this.formData.PriceId = this.sub.Subscription.PaddlePriceId
      this.formData.Count = this.sub.Subscription.Users - this.sub.Subscription.LifetimeFreeUsers 
    },
    methods: {
      async editSubscription() {
        this.loading = 'edit';
        var res = await this.$store.dispatch("changeSubscription", this.formData);
        this.$emit('closeDialog');

        setInterval(() => {
          window.location.reload()
        },5000);
        this.loading = null;
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      sub() {
          return this.$store.getters.getSubscription;
      },
      paddlePlans() {
          return this.$store.getters.getPaddlePlans;
      },
      plans() {
        var subPlans = [];
        if(this.sub.Subscription.Yearly == true)
        {
          for (let i = 0; i < this.paddlePlans.Items.length; i++) {
            const element = this.paddlePlans.Items[i];
            for (let ii = 0; ii < element.prices.length; ii++) {
              const element2 = element.prices[ii];
              if(element2.billing_cycle.interval == 'year')
              {
                  subPlans.push({
                    plan: element,
                    price: element2
                  });
              }
            }
          }
        }
        else
        {
          for (let i = 0; i < this.paddlePlans.Items.length; i++) {
            const element = this.paddlePlans.Items[i];
            for (let ii = 0; ii < element.prices.length; ii++) {
              const element2 = element.prices[ii];
              if(element2.billing_cycle.interval == 'month')
              {
                  subPlans.push({
                    plan: element,
                    price: element2
                  });
              }
            }
          }
        }

        return subPlans;
      }
    }
}
</script>

